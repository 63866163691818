body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a:link,
a:active,
a:visited,
a:hover {
  background: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.link {
  cursor: pointer;
}

.textLoading {
  animation: 0.8s fade infinite;
  text-align: center;
  font-size: 16px;
}

@keyframes fade {
  0% {
    color: #000
  }
  50% {
    color: #555
  }
  100% {
    color: #999
  }
}
.mIndex {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.home {
  width: 100%;
  margin: 44px auto 0;

  a {
    text-decoration: none;

    &:hover {
      color: #DB0000 !important;
    }
  }
}

.types {
  font-size: 15px;
  color: #666;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    padding: 8px 13px;
    cursor: pointer;
    position: relative;

    &.cur {
      color: #DB0000;

      &:before {
        content: " ";
        position: absolute;
        left: 20px;
        bottom: 0;
        width: 14px;
        height: 3px;
        background: #DB0000;
        border-radius: 2px;
      }

    }

  }
}

.listBox {
  display: flex;
  justify-content: space-between;
  padding: 7px 13px;
  position: relative;
}


.fixedTop {
  max-width: calc(100vw - 26px);

  .picSlide {
    width: 100%;
    height: calc(54.55vw - 15px);
  }


  .pic {
    display: block;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    //margin-bottom: 22px;

    img {
      display: block;
      width: 100%;
      height: calc(54.55vw - 15px);
      //height: 55%;
      object-fit: cover;
      object-position: 0 0;
    }

    .picDesc {
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      padding: 10px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 1;
    }
  }

  .desc a {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 14px;
    color: #161627;
    line-height: 23px;
  }

  .desc a:first-child {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;
  }

  :global(.swiper-pagination) {
    text-align: right;
    padding-right: 10px;
    bottom: 40px !important;
    box-sizing: border-box;
  }

  :global(.swiper-pagination-bullet) {
    width: 7px;
    height: 4px;
    background: #FFFFFF;
    opacity: 0.3;
    border-radius: 2px;
  }

  :global(.swiper-pagination-bullet-active) {
    width: 11px;
    height: 4px;
    background: #FFFFFF;
    border-radius: 2px;
    opacity: 1;
  }

  .hotList {
    margin-top: 15px;
  }

}

.list {

  .item {
    margin-bottom: 15px;
    padding-bottom: 15px;
    display: flex;
    border-bottom: 1px solid #eee;

    .pic {
      margin-left: 20px;
      position: relative;

      img {
        object-fit: cover;
        display: block;
        cursor: pointer;
        width: 120px;
        height: 89px;
        background: #F3F3F3;
        border-radius: 4px;
      }
    }

    .picBig {
      margin: 10px 0;
      img {
        display: block;
        width: 100%;
        height: 117px;
        border-radius: 4px;
        object-fit: cover;
        cursor: pointer;
      }

    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }

    .ttitle {
      cursor: pointer;
      font-size: 15px;
      font-weight: bold;
      color: #161627;
      line-height: 23px;
    }

    .pics {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;

      img {
        display: block;
        width: 32%;
        height: 89px;
        border-radius: 4px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .desc {
      font-size: 13px;
      color: #999999;
      line-height: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .hot {
        margin-right: 5px;
        font-size: 12px;
        font-weight: bold;
        color: #DB0000;
      }

      .dot {
        display: inline-block;
        margin: 0 10px;
        width: 2px;
        height: 2px;
        background: #AAAAAA;
        border-radius: 50%;
      }

    }
  }
}

.listVideo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    width: calc(50% - 5px);
    margin-bottom: 20px;

    .pic {
      margin-bottom: 8px;
      position: relative;

      .bg {
        width: 100%;
        height: 100px;
        border-radius: 4px;
        object-fit: cover;
        display: block;
        cursor: pointer;
      }

      .time {
        position: absolute;
        right: 8px;
        bottom: 10px;
        padding: 5px 6px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;

        color: #FFFFFF;
        line-height: 12px;

        img {
          margin-right: 4px;
          width: 12px;
          height: 12px;
        }

      }
    }

    .title {
      margin-bottom: 8px;
      font-size: 16px;

      font-weight: 500;
      color: #111111;
      cursor: pointer;
      word-break: break-all;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 42px;
    }

    .desc {
      font-size: 12px;

      color: #AAAAAA;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .space {
    width: 210px;
    height: 1px;
    opacity: 0;
  }
}

.list1 {
  margin-bottom: 40px;
  position: relative;

  .line {
    position: absolute;
    left: 8px;
    top: 6px;
    bottom: 0;
    border-right: 1px dashed #E6E6E6;
    z-index: 1;
  }

  .item {
    margin-left: 34px;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      left: -31px;
      top: 4px;
      width: 12px;
      height: 12px;
      background: #FFFFFF;
      border: 4px solid #FFC9C9;
      border-radius: 6px;
      box-sizing: border-box;
    }
  }

  .title {
    margin-bottom: 8px;
    font-size: 18px;

    color: #111111;
    line-height: 20px;
    display: block;
  }

  .desc {
    font-size: 12px;

    color: #999999;
    line-height: 20px;
  }

}

.list2 {

  .item {
    margin-bottom: 40px;
    display: flex;
    margin-bottom: 20px;

    .pic {
      display: block;
      width: 380px;
      height: 220px;
      border-radius: 6px;
      position: relative;

      .img {
        width: 380px;
        height: 220px;
        border-radius: 6px;
      }

      .picDesc {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 16px;
        background: rgba(0, 0, 0, 0.3);

        font-size: 16px;

        color: #FFFFFF;
        line-height: 22px;
        border-radius: 0 0 6px 6px;
      }

      .pm {
        position: absolute;
        left: 10px;
        top: 0;
        width: 41px;
        z-index: 2;
      }

      .time {
        position: absolute;
        right: 16px;
        top: 16px;
        background: rgba(0, 0, 0, 0.4);
        padding: 5px 8px;
        border-radius: 4px;
        font-size: 14px;

        color: #FFFFFF;
        z-index: 2;
      }
    }

    .picSm {
      display: block;
      margin-right: 12px;
      position: relative;

      img {
        width: 168px;
        height: 106px;
        border-radius: 4px;
      }

      .time {
        position: absolute;
        right: 11px;
        bottom: 11px;
        background: rgba(0, 0, 0, 0.4);
        padding: 5px;
        border-radius: 4px;
        font-size: 12px;

        color: #FFFFFF;
        z-index: 2;
        line-height: 1;
      }

      .pm {
        position: absolute;
        left: 0;
        top: 0;
        padding: 5px 8px;
        z-index: 2;
        background: #000;
        opacity: 0.6;
        border-radius: 4px;

        font-size: 14px;

        color: #FFFFFF;
        line-height: 1;
      }
    }

    .info {
      padding-bottom: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 16px;

        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .desc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;

        color: #AAAAAA;
        line-height: 20px;
      }
    }

  }
}

.download {
  display: block;

  img {
    width: 380px;
    height: 314px;
    display: block;
    border-radius: 7px;
  }
}

.listHd {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .pic1 {
    display: block;
    width: 16px;
    height: 16px;
    padding-top: 1px;
  }

  .pic2 {
    display: block;
    height: 18px;
  }

  .tit {
    margin: 0 21px 0 11px;
    font-size: 18px;

    font-weight: 500;
    color: #111111;
    line-height: 20px;
  }

  .more {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;

    &:hover {
      background: #f3f3f3;
      border-radius: 4px;
    }
  }
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 5px;
    width: 18px !important;
    height: 18px !important;
    object-fit: contain !important;
    background: transparent !important;
  }
}


.container {
  position: relative;
  .banner {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .containerBox {
    margin-top: -20px;
    background-color: #FFFFFF;
    border-radius: 10px 10px 0 0;
    padding: 30px 12px;
    .title {
      font-size: 18px;
      color: #333;
    }
    .sub {
      margin-top: 10px;
      font-size: 12px;
      color: #999;
    }
    .ctitle {
      font-size: 18px;
      color: #3D3D3D;
      font-weight: bold;
      margin: 10px 0;
    }
    .nav {
      position: -webkit-sticky;
      position: sticky;
      top: 0px;
      width: 100%;
      z-index: 999;
      padding: 5px 0;
      background-color: #FFFFFF;
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 23px 0;
      display: flex;
      white-space: nowrap;
      a {
        margin-right: 10px;
        font-weight: 12px;
        color: #3D3D3D;
        background-color: #DFDFDF;
        padding: 2px 7px;
        border-radius: 9.5px;
        &.active {
          background-color: #FF4A4A;
          color: #FFFFFF;
        }
      }
    }
  }
}

.commentBox {
  margin-bottom: 80px;

  .htitle {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
  }

  .list {
    padding: 13px 16px;

    .citem {
      padding: 14px 0 15px;
      position: relative;

      &:first-child {
        padding-top: 0;
      }

      &:last-child:after {
        display: none;
      }

      &:after {
        content: "";
        position: absolute;
        left: 38px;
        right: 0;
        bottom: 1px;
        height: 1px;
        background: #EBEBF0;
        transform: scaleY(0.5);
        transform-origin: 0 0;
      }

      .hd {
        display: flex;
        align-items: center;
        margin-bottom: 3px;

        .headPic {
          width: 30px;
          height: 30px;
          margin-right: 8px;
          border-radius: 100%;
          object-fit: cover;
          background: #eee;
        }

        .name {
          font-size: 12px;
          font-weight: 500;
          color: #666666;
          line-height: 17px;
        }
      }

      .bd {
        padding-left: 38px;
        font-size: 14px;
        font-weight: 500;
        color: #3D3D3D;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .ft {
        margin-top: 4px;
        padding-left: 38px;
        font-size: 12px;
        font-weight: 500;
        color: #666666;
        line-height: 17px;
      }
    }
  }
}
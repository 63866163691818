.container{
  margin-top: 70px;
  a {
    text-decoration: none;
    &:hover {
      color: #FF0000;
    }
    &:active {
      color: #FF0000;
    }
    &:visited {
      color: unset;
    }
  }
  .banner {
    position: relative;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: auto;
      display: block;
      border: 0;
    }
    .bannberMask {
      position: absolute;
      bottom: -15px;
      width: 100%;
      height: 130px;
      background: url("./mask.2120d0e8.png");
      background-size: cover;
    }
    .bannerDesc {
      position: relative;
      margin: -163px auto 0;
      width: 1000px;
      h2 {
        min-height: 50px;
        line-height: 50px;
        overflow: hidden;
        font-weight: 600;
        font-size: 36px;
        color: #ffffff;
      }
      p {
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(232, 232, 232, 1);
        line-height: 20px;
        overflow: hidden;
      }
      
    }
  }
  .contentBox {
    width: 1400px;
    margin: 45px auto 0 auto;
    display: flex;
    justify-content: space-between;
    .categoryBox {
      position: sticky;
      top: 80px;
      width: 163px;
      max-height: 500px;
      list-style: none;
      padding: 0;
      margin: 0;
      a {
        // height: 60px;
        // line-height: 60px;
        // font-size: 18px;
        color: #3D3D3D;
        // text-align: center;
        background: #F6F6F6;
        cursor: pointer;
        &.active {
          background: #FF0000;
          color: #fff;
          span {
            background: #FF0000;
            color: #fff;
          }
        }
        span {
          display: block;
          padding: 13px 18px;
          line-height: 20px;
          font-size: 14px;
          font-weight: bolder;
          color: #3D3D3D;
          overflow: hidden;
        }
      }
    }
    .leftList {
      width: 800px;
      .ltitle {
        color: #3D3D3D;
        font-size: 14px;
      }
      .ctitle {
        margin: 18px 0 0 0;
        font-size: 22px;
        font-weight: bolder;
        color: #3D3D3D;
        position: relative;
        padding-left: 10px;
        &::before {
          content: '';
          width: 2px;
          height: 14px;
          background: #FF0000;
          position: absolute;
          left: 0;
          top: 7px;
        }
      }
      .list {
        .item {
          border-bottom: 1px solid #D8D8D8;
          padding: 20px 0;
          // margin-bottom: 51px;
          display: flex;
      
          .pic {
            margin-right: 22px;
            position: relative;
            img {
              width: 190px;
              height: 124px;
              border-radius: 6px;
              object-fit: cover;
              display: block;
              cursor: pointer;
            }
          }

          .play {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -20px 0 0 -20px;
            width: 40px;
            height: 40px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          
            img {
              margin-left: 5px;
              width: 18px !important;
              height: 18px !important;
              object-fit: contain !important;
            }
          }
      
          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            padding-bottom: 5px;
          }
      
          .title {
            font-size: 16px;
            font-weight: 500;
            color: #111111;
            line-height: 28px;
            cursor: pointer;
          }
      
          .pics {
            margin: 10px 5px 0 0;
            display: flex;
            justify-content: space-between;
            img {
              display: block;
              width: 254px;
              height: 170px;
              border-radius: 6px;
              object-fit: cover;
              cursor: pointer;
            }
          }
      
          .desc {
            margin-top: 17px;
            font-size: 12px;
            color: #999999;
            line-height: 20px;
            display: flex;
            align-items: center;
      
            .dot {
              display: inline-block;
              margin: 0 10px;
              width: 2px;
              height: 2px;
              background: #AAAAAA;
              border-radius: 50%;
            }
      
          }
        }
      }

      .commentsTitle {
        font-size: 22px;
        color: #3D3D3D;
        font-weight: 400;
        margin: 20px 0;
        position: relative;
        padding-left: 10px;
        &::before {
          content: '';
          width: 2px;
          height: 14px;
          background: #FF0000;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
      .commentBox {
        margin: 0 auto;
        width: 674px;
        display: none;
        textarea {
          width: 100%;
          height: 146px;
          border-radius: 3px;
          border: 1px solid #666666;
          padding-left: 10px;
        }
        .commentBtn {
          margin-top: 26px;
          display: inline-block;
          width: 94px;
          height: 38px;
          line-height: 38px;
          border-radius: 3px;
          opacity: 1;
          background: #FF2929;
          font-size: 16px;
          color: #fff;
          text-align: center;
        }
      }

    }
    .rightList {
      width: 380px;
      .download {

        img {
          width: 380px;
          height: 314px;
          display: block;
          border-radius: 7px;
        }
      }
    }
  }
}

.commentsList {

  .item {
    margin-bottom: 24px;
    border-bottom: 1px solid #D8D8D8;

    &:last-child .detail {
      border-bottom: 0;
    }

    .hd {
      display: flex;
      align-content: center;

      img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-right: 10px;
      }

      .t {
        display: flex;
        align-items: center;

        .userName {
          margin-right: 8px;
          font-size: 14px;
          color: #111111;
          line-height: 20px;
        }

        .time {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }
    }

    .detail {
      flex: 1;
      padding-bottom: 20px;
      border-bottom: 1px solid #EEEEEE;
      margin-left: 50px;
      margin-top: 6px;

      .comment {
        font-size: 14px;
        color: #3D3D3D;
        line-height: 20px;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }

  }
}

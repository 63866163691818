.box {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  // max-height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;

  .logo {
    height: 35px;
  }

  .ft {
    width: 50%;
    // position: fixed;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // background: #fff;
    // padding: 23px 41px;
    // font-size: 14px;
    // font-weight: 500;

    img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    a {
      display: block;
      text-decoration: none;
      outline: none!important;

      box-shadow: none!important;

    }

    .btn1 {
      //margin-bottom: 23px;
      height: 35px;
      background: #F62408;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FEFEFE;
    }

    .btn2 {
      height: 35px;
      border: 1.5px solid #F62408;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      color: #F62408;
      margin-top: 20px;
      display: none;
    }

  }
}

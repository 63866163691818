
.newsBox {
  padding: 13px 16px;
}

.title {
  margin-bottom: 14px;
  font-size: 24px;
  font-weight: 800;
  color: #333333;
  line-height: 33px;
}

.tools {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .author {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headPic {
      margin-right: 6px;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      overflow: hidden;
      object-fit: cover;
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .time {
      font-size: 12px;
      font-weight: 500;
      color: #666666;
      line-height: 17px;
    }
  }

  .focus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #D2D2D2;

    font-size: 13px;
    font-weight: 500;
    color: #999999;
  }
}

.detailBox {
  margin-top: 18px;

  .p, p {
    font-size: 18px;
    color: #333333;
    line-height: 2;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto 10px;
    object-fit: contain;
  }
}

.videoBox {
  :global(.prism-player .prism-info-display){
    box-sizing: border-box;
  }
  .video {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .inner {
    margin: 13px 16px;
  }

  .name {
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }

  .info {
    margin-bottom: 20px;
    font-size: 12px;
    color: #999999;
    line-height: 18px;
  }


  .list {
    margin-top: 20px;

    .item {
      display: flex;
      padding: 14px 0;
      border-bottom: 1px solid #EBEBF0;
      text-decoration: none;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 1px;
      }

      .l {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        .t1 {
          font-size: 15px;
          color: #333333;
          line-height: 21px;
        }

        .t2 {
          font-size: 12px;
          color: #999999;
          line-height: 18px;
        }
      }

      .pic {
        width: 130px;
        height: 90px;
        margin-left: 10px;
        position: relative;

        img {
          width: 130px;
          height: 90px;
          object-fit: cover;
          position: relative;
          z-index: 1;
          border-radius: 4px;
        }

        .time {
          background: rgba(0, 0, 0, 0.7);
          border-radius: 12px;
          position: absolute;
          z-index: 2;
          right: 4px;
          bottom: 4px;
          padding: 2px 6px;

          font-size: 11px;
          color: #FFFFFF;
          line-height: 16px;
        }
      }


    }
  }
}

.commentBox {
  margin-bottom: 80px;

  .title {
    padding-left: 16px;
    margin-bottom: 0;
    height: 30px;
    background: #F2F5F6;
    font-size: 11px;
    font-weight: 500;
    color: #333333;
    line-height: 16px;

    display: flex;
    align-items: center;
  }

  .list {
    padding: 13px 16px;

    .item {
      padding: 14px 0 15px;
      position: relative;

      &:first-child {
        padding-top: 0;
      }

      &:last-child:after {
        display: none;
      }

      &:after {
        content: "";
        position: absolute;
        left: 38px;
        right: 0;
        bottom: 1px;
        height: 1px;
        background: #EBEBF0;
        transform: scaleY(0.5);
        transform-origin: 0 0;
      }

      .hd {
        display: flex;
        align-items: center;
        margin-bottom: 3px;

        .headPic {
          width: 30px;
          height: 30px;
          margin-right: 8px;
          border-radius: 100%;
          object-fit: cover;
          background: #eee;
        }

        .name {
          font-size: 12px;
          font-weight: 500;
          color: #666666;
          line-height: 17px;
        }
      }

      .bd {
        padding-left: 38px;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: 20px;
      }

      .ft {
        margin-top: 4px;
        padding-left: 38px;
        font-size: 12px;
        font-weight: 500;
        color: #666666;
        line-height: 17px;
      }
    }
  }
}

.nodata {
  text-align: center;
  font-size: 12px;
  color: #999;
  padding: 10px 10px 15px;
}

.loadEffect {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 auto;
  margin-top: 100px;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: lightgreen;
    position: absolute;
    animation: load 1.04s ease infinite;
    opacity: 0.2;
  }

  span:nth-child(1) {
    left: 1px;
    top: 50%;
    margin-top: -4px;
    animation-delay: 0.13s;
  }

  span:nth-child(2) {
    left: 7px;
    top: 7px;
    animation-delay: 0.26s;
  }

  span:nth-child(3) {
    left: 50%;
    top: 1px;
    margin-left: -4px;
    animation-delay: 0.39s;
  }

  span:nth-child(4) {
    top: 7px;
    right: 7px;
    animation-delay: 0.52s;
  }

  span:nth-child(5) {
    right: 1px;
    top: 50%;
    margin-top: -4px;
    animation-delay: 0.65s;
  }

  span:nth-child(6) {
    right: 7px;
    bottom: 7px;
    animation-delay: 0.78s;
  }

  span:nth-child(7) {
    bottom: 1px;
    left: 50%;
    margin-left: -4px;
    animation-delay: 0.91s;
  }

  span:nth-child(8) {
    bottom: 7px;
    left: 7px;
    animation-delay: 1.04s;
  }
}

@keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.picNews {
  height: 100vh;
  background-color: #000;
  .bottom {
    position: absolute;
    bottom: 8vh;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 15px;
    z-index: 2;
    height: 20vh;
    .nav {
      width: 48px;
      height: 25px;
      line-height: 25px;
      background: rgba(216, 216, 216, 0.38);
      border-radius: 12.5px;
      font-size: 14px;
      text-align: center;
      color: #fff;
    }
    .line {
      background: #D8D8D8;
      width: 3px;
      height: 82px;
      border-radius: 1.5px;
    }
    .atitle {
      margin-top: 5px;
      color: #fff;
      font-weight: bold;
      font-size: 17px;
    }
    .desc {
      margin-top: 10px;
      font-size: 15px;
      color: #fff;
      line-height: 24px;
      height: 15vh;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .topBox {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      vertical-align: middle;
    }
    .name {
      display: inline-block;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 10px;
    }
    .nav {
      width: 48px;
      height: 25px;
      line-height: 25px;
      background: rgba(216, 216, 216, 0.38);
      border-radius: 12.5px;
      font-size: 14px;
      text-align: center;
      color: #fff;
    }
    .txt {
      width: 56px;
      height: 24px;
      background-color: #FF0000;
      color: #FFFFFF;
      text-align: center;
      border-radius: 12px;
    }
  }
  .box {

  }

  .picSlide {
    width: 100%;
    height: 85vh;
    text-align: center;
    line-height: 85vh;
  }

  .pic1 {
    max-width: 100%;
    max-height: 85vh;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
      object-fit: contain;
    }
  }

  :global(.swiper-button-prev), :global(.swiper-button-next) {
    opacity: 0;
  
    &:after {
      opacity: 0;
    }
  
    &:global(.swiper-button-disabled) {
      opacity: 0;
    }
  }
  
  :global(.swiper-button-next) {
    left: auto;
    right: 0;
    display: none;
  }

}


.home {
  width: 100%;
  margin: 70px auto 0;

  a {
    text-decoration: none;

    &:hover {
      color: #DB0000 !important;
    }
  }

  .container {
    width: 1100px;
    background-color: #fff;
    max-width: 1100px;
    margin: 0 auto;
    .breadcrumb {
      position: fixed;
      top: 40px;
      margin-left: 160px;
      z-index: 11;
      font-size: 12px;
      color: #111111;
  
      a{
        cursor: pointer;
      }
    }
    .title {
      margin: 23px 0;
      font-size: 24px;
      line-height: 24px;
      color: #3D3D3D;
      font-weight: normal;
    }
    .subtitle {
      font-size: 16px;
      color: #999999;
      font-weight: normal;
    }
    .box {
      // margin-top: 67px;
    }
  }

  :global(.swiper-button-prev), :global(.swiper-button-next) {
    width: 53px;
    height: 80px;
    background: #E5E5E5;
    left: auto;
    transition: opacity 0.2s;
  
    &:after {
      font-size: 44px;
      color: #929292;
    }
  
    // &:global(.swiper-button-disabled) {
    //   opacity: 0;
    // }
  }
  
  :global(.swiper-button-next) {
    left: auto;
    right: 0;
  }

}
.banner {
  margin-bottom: 20px;
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}


.comments {
  margin-top: 80px;

  .commentsList {

    .item {
      margin-bottom: 24px;

      &:last-child .detail {
        border-bottom: 0;
      }

      .hd {
        display: flex;
        align-content: center;

        img {
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          margin-right: 10px;
        }

        .t {
          display: flex;
          align-items: center;

          .userName {
            margin-right: 8px;
            font-size: 14px;
            color: #111111;
            line-height: 20px;
          }

          .time {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
        }
      }

      .detail {
        flex: 1;
        padding-bottom: 20px;
        border-bottom: 1px solid #EEEEEE;
        margin-left: 50px;
        margin-top: 6px;

        .comment {
          font-size: 14px;
          color: #111111;
          line-height: 20px;
          word-break: break-word;
        }
      }

    }
  }

}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px dashed #E6E6E6;

  .l {
    flex: 1;
    font-size: 12px;
    color: #999999;
    line-height: 24px;

    div {

    }
  }

  .r {
    margin-left: 18px;
    cursor: pointer;
    position: relative;
    z-index: 3;

    &:hover {
      .qrcodeBox {
        display: block;
      }
    }

    .qrcodeBox {
      display: none;
      position: absolute;
      left: 40px;
      top: 0;
      z-index: 3;
      border-radius: 8px;
      text-align: center;
      width: 139px;
      height: 168px;
      background: #fff;
      box-shadow: 0 10px 50px rgba(54, 56, 79, 0.11);

      .qrcode {
        margin: 30px auto 0;
        width: 80px;
        height: 80px;
      }

      p {
        text-align: center;
        margin-top: 21px;
        font-size: 14px;
        line-height: 20px;
        color: #222;
      }
    }

    img {
      width: 32px;
      height: 32px;
      display: block;
    }
  }

}

.commentsTitle {
  font-size: 22px;
  color: #3D3D3D;
  font-weight: 400;
  margin: 20px 0;
  position: relative;
  padding-left: 10px;
  &::before {
    content: '';
    width: 2px;
    height: 14px;
    background: #FF0000;
    position: absolute;
    left: 0;
    top: 8px;
  }
}

.picSlide {
  width: 950px;
  height: 625px;
  text-align: center;
}

.pic {
  margin-bottom: 36px;
  max-width: 800px;
  height: 625px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .picDesc {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 20px;
    font-weight: 100;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 7px 7px;
  }
}

.bottom {
  margin: 20px 0 86px 0;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .nav {
    font-size: 20px;
    color: #3D3D3D;
    font-weight: normal;
    .cur {
      font-size: 50px;
    }
  }
  .line {
    background: #D8D8D8;
    width: 3px;
    height: 82px;
    border-radius: 1.5px;
  }
  .desc {
    width: 70%;
    font-size: 16px;
    color: #3D3D3D;
    border-left: 3px solid #D8D8D8;
    padding-left: 60px;
    border-radius: 1.5px;
  }
}
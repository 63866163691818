.home {
  width: 1100px;
  margin: 90px auto 0;

  .breadcrumb {
    position: fixed;
    top: 40px;
    margin-left: 160px;
    z-index: 11;

    font-size: 12px;
    color: #111111;

    a{
      cursor: pointer;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      color: #DB0000 !important;
    }
  }
}

.listBox {
  display: flex;
  justify-content: space-between;

  .leftList {
    width: 660px;
  }

  .rightList {
    width: 380px;
  }

}

.article {
  .title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    color: #222222;
    line-height: 42px;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px dashed #E6E6E6;

    .l {
      flex: 1;
      font-size: 12px;
      color: #999999;
      line-height: 24px;

      div {

      }
    }

    .r {
      margin-left: 18px;
      cursor: pointer;
      position: relative;
      z-index: 3;

      &:hover {
        .qrcodeBox {
          display: block;
        }
      }

      .qrcodeBox {
        display: none;
        position: absolute;
        left: 40px;
        top: 0;
        z-index: 3;
        border-radius: 8px;
        text-align: center;
        width: 139px;
        height: 168px;
        background: #fff;
        box-shadow: 0 10px 50px rgba(54, 56, 79, 0.11);

        .qrcode {
          margin: 30px auto 0;
          width: 80px;
          height: 80px;
        }

        p {
          text-align: center;
          margin-top: 21px;
          font-size: 14px;
          line-height: 20px;
          color: #222;
        }
      }

      img {
        width: 32px;
        height: 32px;
        display: block;
      }
    }

  }

  .detail {
    word-break: break-word;
    text-align: justify;
    line-height: 2;
    text-indent: 2em;
    font-size: 16px;

    img {
      max-width: 100%;
    }

  }

  .video {
    margin: 28px 0 60px;
    object-fit: contain;
    position: relative;
    z-index: 1;
  }


  .author {
    margin-top: 40px;
    font-size: 12px;
    color: #222222;
    line-height: 30px;
  }
}

.comments {
  margin-top: 80px;

  .commentsList {

    .item {
      margin-bottom: 24px;

      &:last-child .detail {
        border-bottom: 0;
      }

      .hd {
        display: flex;
        align-content: center;

        img {
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          margin-right: 10px;
        }

        .t {
          display: flex;
          align-items: center;

          .userName {
            margin-right: 8px;
            font-size: 14px;
            color: #111111;
            line-height: 20px;
          }

          .time {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
        }
      }

      .detail {
        flex: 1;
        padding-bottom: 20px;
        border-bottom: 1px solid #EEEEEE;
        margin-left: 50px;
        margin-top: 6px;

        .comment {
          font-size: 14px;
          color: #111111;
          line-height: 20px;
          word-break: break-word;
        }
      }

    }
  }

}

.list1 {
  margin-bottom: 40px;
  position: relative;

  .line {
    position: absolute;
    left: 8px;
    top: 6px;
    bottom: 0;
    border-right: 1px dashed #E6E6E6;
    z-index: 1;
  }

  .item {
    margin-left: 34px;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      left: -31px;
      top: 4px;
      width: 12px;
      height: 12px;
      background: #FFFFFF;
      border: 4px solid #FFC9C9;
      border-radius: 6px;
      box-sizing: border-box;
    }
  }

  .title {
    margin-bottom: 8px;
    font-size: 16px;
    color: #111111;
    line-height: 20px;
    display: block;
  }

  .desc {
    font-size: 12px;
    color: #999999;
    line-height: 20px;
  }

}

.list2 {

  .item {
    margin-bottom: 40px;
    display: flex;
    margin-bottom: 20px;

    .pic {
      display: block;
      width: 380px;
      height: 220px;
      border-radius: 6px;
      position: relative;

      .img {
        width: 380px;
        height: 220px;
        border-radius: 6px;
      }

      .picDesc {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 16px;
        background: rgba(0, 0, 0, 0.3);

        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        border-radius: 0 0 6px 6px;
      }

      .pm {
        position: absolute;
        left: 10px;
        top: 0;
        width: 41px;
        z-index: 2;
      }

      .time {
        position: absolute;
        right: 16px;
        top: 16px;
        background: rgba(0, 0, 0, 0.4);
        padding: 5px 8px;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        z-index: 2;
      }
    }

    .picSm {
      display: block;
      margin-right: 12px;
      position: relative;

      img {
        width: 168px;
        height: 106px;
        border-radius: 4px;
      }

      .time {
        position: absolute;
        right: 11px;
        bottom: 11px;
        background: rgba(0, 0, 0, 0.4);
        padding: 5px;
        border-radius: 4px;
        font-size: 12px;
        color: #FFFFFF;
        z-index: 2;
        line-height: 1;
      }

      .pm {
        position: absolute;
        left: 0;
        top: 0;
        padding: 5px 8px;
        z-index: 2;
        background: #000;
        opacity: 0.6;
        border-radius: 4px;

        font-size: 14px;
        color: #FFFFFF;
        line-height: 1;
      }
    }

    .info {
      padding-bottom: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .desc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #AAAAAA;
        line-height: 20px;
      }
    }

  }
}

.listVideo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    width: 260px;
    margin-bottom: 30px;

    .pic {
      margin-bottom: 15px;
      position: relative;

      .bg {
        width: 260px;
        height: 184px;
        border-radius: 6px;
        object-fit: cover;
        display: block;
        cursor: pointer;
      }

      .play {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-left: 5px;
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
      }

      .time {
        position: absolute;
        right: 8px;
        bottom: 10px;
        padding: 5px 6px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 12px;

        img {
          margin-right: 4px;
          width: 12px;
          height: 12px;
        }

      }
    }

    .info {
    }

    .title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      cursor: pointer;
      word-break: break-all;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .desc {
      font-size: 12px;
      color: #AAAAAA;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .space {
    width: 260px;
    height: 1px;
    opacity: 0;
  }
}


.download {
  display: block;

  img {
    width: 380px;
    height: 314px;
    display: block;
    border-radius: 7px;
  }
}

.listHd {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .pic1 {
    display: block;
    width: 16px;
    height: 16px;
    padding-top: 1px;
  }

  .pic2 {
    display: block;
    height: 18px;
  }

  .tit {
    margin: 0 21px 0 11px;
    font-size: 18px;
    font-weight: 500;
    color: #111111;
    line-height: 20px;
  }

  .more {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;

    &:hover {
      background: #f3f3f3;
      border-radius: 4px;
    }
  }
}

.nodata {
  text-align: center;
  font-size: 12px;
  color: #999;
  padding: 10px 10px 15px;
}

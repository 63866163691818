.box {
  background: url(pic/bg.jpg) center no-repeat #fff;
  background-size: 1400px auto;
  width: 100%;
  max-height: 100vh;
  padding: 150px 0;
  margin-bottom: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;

  .pic1 {
    width: 522px;
    margin-right: 150px;
    object-fit: contain;
  }

  .links {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;

    .logo {
      margin: 0 auto 166px;
      display: block;
    }

    .btns{
      display: flex;
      align-items: center;
    }

    .code{
      width: 94px;
      height: 94px;
      background: #FFFFFF;

      img{
        width: 90px;
        height: 90px;
        margin: 2px;
        object-fit: contain;
        display: block;
      }
    }


    a {
      display: flex;
      flex-direction:column ;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      img {
        width: 44px;
        height: 44px;
        margin: 0 auto 18px;
      }
    }

    .btn1 {
      margin-top: 15px;
      margin-right: 60px;
      margin-bottom: 21px;
      color: #666;
    }

    .btn2 {
      margin-top: 15px;
      margin-right: 60px;
      margin-bottom: 21px;
      color: #666;
    }

  }
}

.searchPage {

  width: 100%;
  overflow-x: hidden;

  margin: 54px auto 0;

  a {
    text-decoration: none;

    &:hover {
      color: #DB0000 !important;
    }
  }
}

.types {
  margin-bottom: 30px;
  font-size: 15px;
  color: #777777;
  line-height: 20px;
  overflow: hidden;
  border-bottom: 1px solid #E6E6E6;

  a {
    padding: 12px 20px;
    cursor: pointer;
    float: left;
    display: block;
    position: relative;

    &.cur {
      color: #DB0000;

      &:before {
        content: " ";
        position: absolute;
        left: 24px;
        width: 24px;
        bottom: -0px;
        height: 4px;
        background: #DB0000;
        border-radius: 2px;
      }

    }

    &:hover {
      color: #DB0000;
    }

  }
}

:global(.header .search) {
  display: none !important;
}

.search {
  height: 36px;
  margin: 10px 13px;
  background: #F7F8FA;
  border-radius: 18px;
  display: flex;
  align-items: center;
  padding-left: 16px;

  input {
    flex: 1;
    line-height: 36px;
    border: none;
    height: 36px;
    font-size: 16px;
    background: transparent;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #BBBBBB;
    }
  }

  .btn {
    //width: 30px;
    height: 30px;
    padding: 6px 17px 8px 15px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 0 15px 15px 0;
    border: 0;
    background: transparent;

    &:hover {
      background: #eee;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }

}

.listBox {
  padding: 0 13px;

  :global(.textLoading) {
    font-size: 14px !important;
  }

}

.list {
  width: 100%;

  .item {
    margin-bottom: 30px;
    display: flex;

    .pic {
      margin-right: 22px;

      img {
        width: 230px;
        height: 130px;
        border-radius: 6px;
        object-fit: cover;
        display: block;
        cursor: pointer;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding-bottom: 5px;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      cursor: pointer;
    }

    .pics {
      margin: 10px 0 20px;
      display: flex;

      img {
        display: block;
        width: 210px;
        height: 118px;
        margin-right: 10px;
        border-radius: 6px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .desc {
      font-size: 12px;
      color: #999999;
      line-height: 20px;
      display: flex;
      align-items: center;

      .dot {
        display: inline-block;
        margin: 0 10px;
        width: 2px;
        height: 2px;
        background: #AAAAAA;
        border-radius: 50%;
      }

    }
  }
}

.listVideo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    width: 210px;
    margin-bottom: 30px;

    .pic {
      margin-bottom: 15px;
      position: relative;

      .bg {
        width: 210px;
        height: 148px;
        border-radius: 6px;
        object-fit: cover;
        display: block;
        cursor: pointer;
      }

      .play {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-left: 5px;
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
      }

      .time {
        position: absolute;
        right: 8px;
        bottom: 10px;
        padding: 5px 6px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 12px;

        img {
          margin-right: 4px;
          width: 12px;
          height: 12px;
        }

      }
    }

    .info {
    }

    .title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      cursor: pointer;
      word-break: break-all;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .desc {
      font-size: 12px;
      color: #AAAAAA;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .space {
    width: 210px;
    height: 1px;
    opacity: 0;
  }
}

.tips {
  text-align: center;
  font-size: 14px;
  color: #555;
}

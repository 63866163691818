:global(body) {
  font-size: 14px;
  line-height: 1.4;
}

.layoutBox {
}

.header {
  height: 70px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background: #fff;

  &.shadow {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
  }

  .mainBox {
    max-width: 1100px;
    //padding: 0 30px;
    margin: 0 auto;
    height: 70px;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 146px;
    height: 46px;

    img {
      width: 146px;
      height: 46px;
    }
  }

  .publish {
    display: inline-block;
    width: 84px;
    height: 28px;
    border: 1px  solid #db0000;
    border-radius: 15px;
    text-align: center;
    line-height: 28px;
    color: #DB0000;
    font-size: 12px;
    text-decoration: none;
    margin-left: 46px;
  }

  .breadcrumb{
    margin-bottom: -27px;
    margin-left: 20px;
  }

  .search {
    width: 274px;
    height: 30px;
    background: #F5F5F5;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding-left: 16px;

    input {
      flex: 1;
      line-height: 30px;
      border: none;
      height: 30px;
      background: transparent;
      padding: 0;
      margin: 0;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #BBBBBB;
      }
    }

    .btn {
      //width: 30px;
      height: 30px;
      padding: 6px 17px 8px 15px;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 0 15px 15px 0;
      border: 0;
      background: transparent;

      &:hover {
        background: #eee;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }

  }
}

.footer {
  margin-top: 50px;
  background: #F7F8FA;

  .inner {
    width: 1100px;
    margin: 0 auto;
    padding: 48px 0;
    font-size: 14px;
    display: flex;
    box-sizing: border-box;
  }

  .l {
    flex: 1;
  }

  .r {
    display: flex;

    .code {
      background: #fff;

      img {
        display: block;
        width: 100px;
        height: 100px;
        box-sizing: border-box;
      }
    }

    .qrcode {
      margin-left: 39px;
    }

    .name {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      color: #111;
    }


  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  .links {
    margin-bottom: 37px;
    display: flex;
    align-items: center;

    a {
      font-size: 14px;
      color: #111;
    }

    .split {
      width: 1px;
      height: 12px;
      margin: 0 29px;
      background: #ddd;
    }
  }

  .icp {
    margin-bottom: 38px;

    span, a {
      font-size: 12px;
      margin-right: 44px;
      color: #999;
    }
  }

  .copyright {
    font-size: 12px;
    color: #999;
  }
}

.router {
  display: flex;
  height: 80%;
  align-items: center;

  .imgBlock {
    flex: 0 0 62.5%;
    width: 62.5%;
    zoom: 1;
    padding-right: 88px;

    .img {
      float: right;
      height: 360px;
      width: 100%;
      max-width: 430px;
      background-size: contain;
      background: url('./404.svg') no-repeat 50% 50%;
    }
  }

  .title {
    color: #434e59;
    font-size: 72px;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 24px;
  }

  .desc {
    color: rgba(0, 0, 0, .45);
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
}


@media screen and (max-width: 800px) {
  .header {
    //display: none;
  }

  .footer {
    padding: 11px;
    word-break: break-all;
    font-size: 12px;
    color: #aaa;

    a {
      font-size: 12px;
      color: #aaa;
    }

    .line {
      display: none;
    }

    .br {
      display: inline;
    }
  }
}

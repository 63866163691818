.home {
  width: 1100px;
  margin: 70px auto 0;

  a {
    text-decoration: none;

    &:hover {
      color: #DB0000 !important;
    }
  }
}

.types {
  margin-bottom: 15px;
  font-size: 18px;
  color: #777777;
  line-height: 20px;
  overflow: hidden;

  a {
    padding: 12px 32px;
    cursor: pointer;
    float: left;
    display: block;
    position: relative;
    color: #777777;

    &.cur {
      color: #DB0000;

      &:before {
        content: " ";
        position: absolute;
        left: 38px;
        width: 24px;
        bottom: 0;
        height: 4px;
        background: #DB0000;
        border-radius: 2px;
      }

    }

    &:first-child {
      padding-left: 9px;

      &:before {
        left: 14px;
      }
    }

    &:hover {
      color: #DB0000;
    }

  }
}

.banner {
  margin-bottom: 20px;

  img {
    width: 1100px;
    display: block;
  }
}

.topNews {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: -18px;
  margin-right: -18px;

  .line {
    position: absolute;
    left: 18px;
    right: 18px;
    bottom: 1px;
    height: 1px;
    background: #EEEEEE;
    z-index: 2;

  }

  .item {
    display: flex;
    cursor: pointer;
    padding: 0 18px;

    .pic {
      margin-right: 12px;
      width: 130px;
      height: 90px;
      position: relative;

      img {
        width: 130px;
        height: 90px;
      }

      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100px;
          border: 10px solid #fff;
          border-top: none;
          border-right-color: transparent;
        }

        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 10px;
          height: 10px;
          background: #fff;
        }
      }
    }

    .info {
      line-height: 20px;
      font-weight: 500;

      .tit {
        margin-bottom: 12px;
        font-size: 16px;
        color: #111111;
      }

      .desc {
        font-size: 12px;
        color: #999;
        width: 204px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.listBox {
  display: flex;
  justify-content: space-between;

  .leftList {
    width: 660px;
  }

  .rightList {
    width: 380px;
  }

}


.fixedTop {

  :global(.swiper:hover .swiper-button-prev),
  :global(.swiper:hover .swiper-button-next), {
    opacity: 1;

    &:global(.swiper-button-disabled) {
      opacity: 0.5;
      cursor: default;
    }
  }

  :global(.swiper-button-prev), :global(.swiper-button-next) {
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    opacity: 0;
    transition: opacity 0.2s;

    &:after {
      font-size: 20px;
      color: #fff;
    }

    &:global(.swiper-button-disabled) {
      opacity: 0;
    }
  }

  :global(.swiper-button-next) {
    left: auto;
    right: 0;
  }


  .picSlide {
    width: 660px;
    height: 366px;
  }

  .pic {
    display: block;
    position: relative;
    margin-bottom: 36px;

    img {
      display: block;
      width: 660px;
      height: 366px;
      border-radius: 7px;
    }

    .picDesc {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 15px 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: 20px;
      font-weight: 100;
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0 0 7px 7px;
    }
  }

  .topList .item {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 20px;

    a {
      color: #111111;
    }
  }

  .topList .item:first-child {
    margin-top: 41px;
    font-size: 20px;
  }

  .topList .item:last-child {
    margin-bottom: 30px;
  }

}

.spaceLine {
  height: 1px;
  margin: 30px 0;
  background: #EEEEEE;
}

.list {

  .item {
    margin-bottom: 51px;
    display: flex;

    .pic {
      margin-right: 22px;
      position: relative;

      img {
        width: 230px;
        height: 130px;
        border-radius: 6px;
        object-fit: cover;
        display: block;
        cursor: pointer;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding-bottom: 5px;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      cursor: pointer;
    }

    .pics {
      margin: 10px 0 20px;
      display: flex;
      justify-content: space-between;

      img {
        display: block;
        width: 210px;
        height: 118px;
        border-radius: 6px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .desc {
      font-size: 12px;
      color: #999999;
      line-height: 20px;
      display: flex;
      align-items: center;

      .dot {
        display: inline-block;
        margin: 0 10px;
        width: 2px;
        height: 2px;
        background: #AAAAAA;
        border-radius: 50%;
      }

    }
  }
}

.listVideo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    width: 210px;
    margin-bottom: 30px;

    .pic {
      margin-bottom: 15px;
      position: relative;

      .bg {
        width: 210px;
        height: 148px;
        border-radius: 6px;
        object-fit: cover;
        display: block;
        cursor: pointer;
      }

      .time {
        position: absolute;
        right: 8px;
        bottom: 10px;
        padding: 5px 6px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 12px;

        img {
          margin-right: 4px;
          width: 12px;
          height: 12px;
        }

      }
    }

    .info {
    }

    .title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      height: 56px;
      cursor: pointer;
      word-break: break-all;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .desc {
      font-size: 12px;
      color: #AAAAAA;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .space {
    width: 210px;
    height: 1px;
    opacity: 0;
  }
}

.list1 {
  margin-bottom: 40px;
  position: relative;

  .line {
    position: absolute;
    left: 8px;
    top: 6px;
    bottom: 0;
    border-right: 1px dashed #E6E6E6;
    z-index: 1;
  }

  .item {
    margin-left: 34px;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      left: -31px;
      top: 4px;
      width: 12px;
      height: 12px;
      background: #FFFFFF;
      border: 4px solid #FFC9C9;
      border-radius: 6px;
      box-sizing: border-box;
    }
  }

  .title {
    margin-bottom: 8px;
    font-size: 16px;
    color: #111111;
    line-height: 20px;
    display: block;
  }

  .desc {
    font-size: 12px;
    color: #999999;
    line-height: 20px;
  }

}

.list2 {

  .item {
    margin-bottom: 40px;
    display: flex;
    margin-bottom: 20px;

    .pic {
      display: block;
      width: 380px;
      height: 220px;
      border-radius: 6px;
      position: relative;

      .img {
        width: 380px;
        height: 220px;
        border-radius: 6px;
      }

      .picDesc {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 16px;
        background: rgba(0, 0, 0, 0.3);

        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        border-radius: 0 0 6px 6px;
      }

      .pm {
        position: absolute;
        left: 10px;
        top: 0;
        width: 41px;
        z-index: 2;
      }

      .time {
        position: absolute;
        right: 16px;
        top: 16px;
        background: rgba(0, 0, 0, 0.4);
        padding: 5px 8px;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        z-index: 2;
      }
    }

    .picSm {
      display: block;
      margin-right: 12px;
      position: relative;

      img {
        width: 168px;
        height: 106px;
        border-radius: 4px;
      }

      .time {
        position: absolute;
        right: 11px;
        bottom: 11px;
        background: rgba(0, 0, 0, 0.4);
        padding: 5px;
        border-radius: 4px;
        font-size: 12px;
        color: #FFFFFF;
        z-index: 2;
        line-height: 1;
      }

      .pm {
        position: absolute;
        left: 0;
        top: 0;
        padding: 5px 8px;
        z-index: 2;
        background: #000;
        opacity: 0.6;
        border-radius: 4px 0 4px 0;

        font-size: 14px;
        color: #FFFFFF;
        line-height: 1;
      }
    }

    .info {
      padding-bottom: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .desc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #AAAAAA;
        line-height: 20px;
      }
    }

  }
}

.download {

  img {
    width: 380px;
    height: 314px;
    display: block;
    border-radius: 7px;
  }
}

.listHd {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .pic1 {
    display: block;
    width: 16px;
    height: 16px;
    padding-top: 1px;
  }

  .pic2 {
    display: block;
    height: 18px;
  }

  .tit {
    margin: 0 21px 0 11px;
    font-size: 18px;
    font-weight: 500;
    color: #111111;
    line-height: 20px;
  }

  .more {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;

    &:hover {
      background: #f3f3f3;
      border-radius: 4px;
    }
  }
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 5px;
    width: 18px !important;
    height: 18px !important;
    object-fit: contain !important;
  }
}

.listTopic {
  .item {
    display: flex;
    border-bottom: 1px solid #D8D8D8;
    padding: 16px 0;
    .cover {
      width: 240px;
      height: 150px;
      img {
        max-width: 240px;
        min-width: 240px;
        height: 100%;
        border-radius: 6px;
      }
    }
    .desc {
      width: 100%;
      padding-left: 24px;
      .title {
        font-size: 18px;
        color: #3D3D3D;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
      }
      .subBox {
        font-size: 14px;
        line-height: 20px;
        color: #999;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        &::after {
          content: '[详情]';
          display: inline;
          color: #F72626;
          padding-left: 5px;
        }
      }
    }
  }
}

.loadMore {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #EEEEEE;
  font-size: 18px;
  color: #3D3D3D;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: inline-block;
    margin-top: -10px;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #000000;
    border-right: 2px solid #000000;
    transform: rotate(135deg);
  }
}
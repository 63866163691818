
.aboutPage {
  padding-top: 70px;

  .banner {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}

.about {
  width: 1100px;
  margin: 0 auto;

  .title {
    margin: 50px 0;
    font-size: 30px;
    font-weight: bold;
    color: #111111;
    text-align: center;
  }

  .contact {
    width: 600px;
    margin: 30px auto 100px;
    .tit {
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #eee;
      font-size: 18px;
    }

    ul {
      margin: 0;
      padding-left: 16px;

      li{
        margin-bottom: 15px;
      }
    }

  }

}


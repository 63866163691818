.header {
  height: 44px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background: #fff;

  &.shadow {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
  }

  .mainBox {
    width: 100%;
    padding: 7px 13px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .logo {
    height: 28px;

    img {
      height: 28px;
    }
  }

  .search {
    flex: 1;
    margin-left: 30px;
    height: 30px;
    background: #F7F8FA;
    border-radius: 15px;
    display: flex;
    align-items: center;

    input {
      flex: 1;
      line-height: 30px;
      border: none;
      height: 30px;
      background: transparent;
      padding: 0;
      margin: 0;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #BBBBBB;
      }
    }

    .btn {
      height: 30px;
      padding: 6px 7px 8px 10px;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 15px 0 0 15px;
      border: none;
      background: transparent;

      &:hover {
        background: #eee;
      }

      img {
        width: 14px;
        height: 14px;
      }
    }

  }
}

.footer {
  margin-top: 30px;
  padding: 21px 13px;
  background: #F7F8FA;
  font-size: 12px;
  color: #111111;
  text-align: center;

  a {
    cursor: pointer;
    text-decoration: none;
  }

  .links ~ .links{
    padding-bottom: 20px;
    border-bottom: 1px solid #dfdfdf;
  }
  .links {
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    a {
      font-size: 14px;
      color: #111111;
    }

    .split {
      width: 1px;
      height: 12px;
      margin: 0 40px;
      background: #999;
      opacity: 0.2;
    }
  }

  .icp {
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: break-word;
    text-align: justify;
    line-height: 23px;
    span, a {
      font-size: 12px;
      color: #999;
    }
  }
}

.ztInfo {
  position: relative;
  z-index: 10;

  img {
    width: 100%;
    height: 340px;
    object-fit: cover;
    z-index: 1;
  }

  .info {
    position: absolute;
    left: 16px;
    right: 16px;
    top: 90px;
    margin: 0 16px;
    z-index: 2;
  }

  .title {
    margin-bottom: 13px;
    font-size: 22px;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 30px;
  }

  .desc {
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 21px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }
}

.lists {
  position: relative;
  //margin-top: -88px;
  //padding-bottom: 100px;
  background: #FFFFFF;
  border-radius: 20px 20px 0 0;
  z-index: 11;

}

.list {

  .tc_title {
    padding: 23px 0 15px;
    font-size: 18px;
    font-weight: 800;
    color: #333333;
    line-height: 25px;
    text-align: center;
  }

  .item {
    display: flex;
    padding: 14px 16px;
    border-bottom: 1px solid #EBEBF0;
    text-decoration: none;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 20px;
    }

    .l {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      .t1 {
        font-size: 15px;
        color: #333333;
        line-height: 21px;
      }

      .t2 {
        font-size: 13px;
        color: #666;
        line-height: 18px;
      }
    }

    .pic {
      width: 130px;
      height: 90px;
      margin-left: 10px;
      position: relative;

      img {
        width: 130px;
        height: 90px;
        object-fit: cover;
        position: relative;
        z-index: 1;
        border-radius: 4px;
      }

      .time {
        background: rgba(0, 0, 0, 0.7);
        border-radius: 12px;
        position: absolute;
        z-index: 2;
        right: 4px;
        bottom: 4px;
        padding: 2px 6px;

        font-size: 11px;
        color: #FFFFFF;
        line-height: 16px;
      }
    }


  }
}

.popTip{
  height: 86px;
  padding: 0 16px;
  background: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;

  .logo img{
    width: 131px;
  }

  .btnRed{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 107px;
    height: 33px;
    background: #D52119;
    border-radius: 5px;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 21px;
    text-decoration: none;
  }
}

.nodata {
  text-align: center;
  font-size: 12px;
  color: #999;
  padding: 10px 10px 15px;
}

.loadEffect {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 auto;
  margin-top: 100px;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: lightgreen;
    position: absolute;
    animation: load 1.04s ease infinite;
    opacity: 0.2;
  }

  span:nth-child(1) {
    left: 1px;
    top: 50%;
    margin-top: -4px;
    animation-delay: 0.13s;
  }

  span:nth-child(2) {
    left: 7px;
    top: 7px;
    animation-delay: 0.26s;
  }

  span:nth-child(3) {
    left: 50%;
    top: 1px;
    margin-left: -4px;
    animation-delay: 0.39s;
  }

  span:nth-child(4) {
    top: 7px;
    right: 7px;
    animation-delay: 0.52s;
  }

  span:nth-child(5) {
    right: 1px;
    top: 50%;
    margin-top: -4px;
    animation-delay: 0.65s;
  }

  span:nth-child(6) {
    right: 7px;
    bottom: 7px;
    animation-delay: 0.78s;
  }

  span:nth-child(7) {
    bottom: 1px;
    left: 50%;
    margin-left: -4px;
    animation-delay: 0.91s;
  }

  span:nth-child(8) {
    bottom: 7px;
    left: 7px;
    animation-delay: 1.04s;
  }
}

@keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}


.wifiPage {
  background: #F6F7F7;
  min-height: 100vh;
  overflow: hidden;

  .banner {
    width: 100%;
    height: 200px;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }

  .infoCard {
    position: relative;
    z-index: 2;
    margin: -20px 16px 16px;
    padding: 10px 20px 25px;
    background: #FFFFFF;
    border-radius: 20px;

    &.static {
      margin: 0 0 16px 0;
      border-radius: 0;
    }

    .title {
      margin-bottom: 10px;
      font-size: 26px;
      font-weight: 800;
      color: #333333;
      line-height: 37px;
    }

    .where {
      display: inline-block;
      margin-bottom: 13px;
      padding: 3px 7px;
      background: #FFEFE0;
      border-radius: 2px;
      border: 1px solid #FFE2C7;
      font-size: 12px;
      color: #A36F3E;
    }

    .note {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }

    .basic {
      margin-top: 15px;

      .t {
        margin-bottom: 9px;
        font-size: 18px;
        font-weight: 800;
        color: #333333;
        line-height: 25px;
      }

      .p {
        margin-bottom: 9px;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
        display: flex;

        .label {
          padding-right: 1em;
          width: 4em;
          flex-shrink: 0;
        }

        .txt {
          color: #333333;
        }
      }
    }
  }


  .detail {
    background: #fff;
    padding: 14px 16px 30px;
    white-space: pre-wrap;
    word-break: break-all;
    text-align: justify;
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;

    .tit {
      margin-top: 20px;
      margin-bottom: 4px;
      font-size: 20px;
      font-weight: 800;
      color: #333333;
      line-height: 28px;
    }

    .ps {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .quote {
      margin-bottom: 15px;

      a {
        color: #333;
        text-decoration: none;
      }
    }

    img {
      max-width: 100%;
    }
  }
}


.nodata {
  text-align: center;
  font-size: 12px;
  color: #999;
  padding: 10px 10px 15px;
}

.loadEffect {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 auto;
  margin-top: 100px;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: lightgreen;
    position: absolute;
    animation: load 1.04s ease infinite;
    opacity: 0.2;
  }

  span:nth-child(1) {
    left: 1px;
    top: 50%;
    margin-top: -4px;
    animation-delay: 0.13s;
  }

  span:nth-child(2) {
    left: 7px;
    top: 7px;
    animation-delay: 0.26s;
  }

  span:nth-child(3) {
    left: 50%;
    top: 1px;
    margin-left: -4px;
    animation-delay: 0.39s;
  }

  span:nth-child(4) {
    top: 7px;
    right: 7px;
    animation-delay: 0.52s;
  }

  span:nth-child(5) {
    right: 1px;
    top: 50%;
    margin-top: -4px;
    animation-delay: 0.65s;
  }

  span:nth-child(6) {
    right: 7px;
    bottom: 7px;
    animation-delay: 0.78s;
  }

  span:nth-child(7) {
    bottom: 1px;
    left: 50%;
    margin-left: -4px;
    animation-delay: 0.91s;
  }

  span:nth-child(8) {
    bottom: 7px;
    left: 7px;
    animation-delay: 1.04s;
  }
}

@keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}


.aboutPage {
  padding-top: 44px;

  .banner {
    height: 160px;
    width: 100%;
    object-fit: cover;
  }
}

.about {
  margin: 0 15px 50px;

  .title {
    margin: 30px 0 20px;
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    text-align: center;
  }

  .p {
    font-size: 14px;
    color: #111111;
    line-height: 1.7;
    text-indent: 2em;
    text-align: justify;
  }
}



.aboutPage {
  padding-top: 70px;

  .banner {
    //height: 360px;
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}

.about {
  width: 1100px;
  margin: 0 auto;

  .title {
    margin: 80px 0 60px;
    font-size: 30px;
    font-weight: bold;
    color: #111111;
    text-align: center;
  }

  .p {
    font-size: 18px;
    color: #111111;
    line-height: 34px;
    text-indent: 2em;
  }
}

